export default class ApiConfig {
  static uploadPicture = '/Settings/pushPicture' //上传图片
  /** 上传资源 */
  static uploadFile = '/V4/Common/Resource/upload'

  // static  registerByEmail = "/UCenter/Account/emailSignUp"; //邮箱注册
  // static  registerByMobile = "/UCenter/Account/mobileSignUp"; //手机注册
  // static  registerByUsername = "/UCenter/Account/accountV2SignUp"; //用户名注册
  // static  universalLogin = "/UCenter/AccountV2/appUniversalLogin"; //三合一登录

  static loginOut = '/UCenter/Users/loginOut' //用户退出
  static userConfigs = '/User/userItems' //用户配置
  // static  universalForget = "/UCenter/Account/universalForget"; //三合一忘记密码找回
  // static  submitForgetUniversal = "/UCenter/Account/submitForgetUniversal"; //三合一忘记密码提交
  // static  forgetCheckUpdate = "/UCenter/Account/forgetCheckUpdate"; //重置密码

  // static  appInitialData = "/UCenter/Account/appIntialData"; //app初始化数据
  static appInitialDataV2 = '/Publics/appIntialDataV2' //app初始化数据

  static verifyLoginPwd = '/UCenter/Security/checkLoginPwd' //验证登录密码

  // static  setBioTradePwd = "/UCenter/Security/setWithoutPwd"; //设置人脸ID

  // static  setTradePwd = "/UCenter/Security/setPaymentPwd"; //设置交易密码
  // static  verifyTradePwd = "/UCenter/Security/checkPaymentPwd"; //验证交易密码
  // static  resetTradePwd = "/UCenter/Security/resetPaymentPwd"; //重置交易密码
  // static  alterTradePwd = "/UCenter/Security/updatePaymentPwd"; //修改交易密码

  // static  updatePassword = "/UCenter/Security/updatePassword"; //修改登录密码
  // static  updateMobile = "/UCenter/Security/updateMobile"; //修改绑定手机
  // static  bindMobile = "/UCenter/Security/bindMobile"; //绑定手机
  // static  bindEmail = "/UCenter/Security/bindEmail"; //绑定邮箱
  static bingGoogleVerifyStatus = '/UCenter/Security/bingGoogleVerifyStatus' //谷歌绑定状态
  // static  getGoogleSecret = "/UCenter/Security/getGoogleSecret"; //登录状态获取谷歌绑定密钥
  static getSignUpGoogleSecret = '/UCenter/Account/getGoogleSecret' //普通账号注册前获取谷歌绑定密钥
  // static  bindGoogleVerify = "/UCenter/Security/bindGoogleVerify"; //绑定谷歌密钥
  static bindSignUpGoogleVerify = '/UCenter/FirstSecurity/bindGoogleVerify' //首次登录绑定谷歌密钥
  static sendSignUpEmailVerify = '/UCenter/FirstSecurity/sendBindEmailCode' //首次登录绑定邮箱时发送邮箱验证码
  static bindSignUpEmailVerify = '/UCenter/FirstSecurity/bindEmail' //首次登录绑定邮箱
  // static  closeGoogleVerify = "/UCenter/Security/closeGoogleVerify"; //关闭谷歌密钥
  // static  openGoogleVerify = "/UCenter/Security/openGoogleVerify"; //开启谷歌密钥
  // static  updateGoogleVerify = "/UCenter/Security/updateGoogleVerify"; //修改谷歌密钥

  // static  sendSignSmsCode = "/UCenter/Account/sendSignSmsCode"; //发送非登录态短信验证码
  // static  sendSmsCode = "/UCenter/Users/sendSmsCode"; //发送已登录态短信验证码
  // static  sendSignEmailCode = "/UCenter/Account/sendSignEmailCode"; //发送非登录态邮箱验证码
  // static  sendEmailCode = "/UCenter/Users/sendEmailCode"; //发送已登录态邮箱验证码
  // static  sendLoginVerifyEmailCode = "/UCenter/LoginVerify/sendEmailCode"; //发送登录二次验证邮箱验证码
  // static  sendLoginVerifyMobileCode = "/UCenter/LoginVerify/sendSmsCode"; //发送登录二次验证手机验证码

  static sendSignUpEmailCode = '/V3/UCenter/Register/emailCode' //发送非登录态邮箱验证码
  static sendSignUpSmsCode = '/V3/UCenter/Register/mobileCode' //发送非登录态短信验证码
  static registerByUsername = '/V3/UCenter/Register/account' //用户名注册
  static registerByEmail = '/V3/UCenter/Register/email' //邮箱注册
  static registerByMobile = '/V3/UCenter/Register/mobile' //手机注册
  static universalLogin = '/V3/UCenter/Login/universal' //三合一登录
  static loginEmailVerify = '/V3/UCenter/LoginVerify/emailVerify' //用邮箱验证码二次登录
  static loginMobileVerify = '/V3/UCenter/LoginVerify/mobileVerify' //用手机验证码二次登录
  static loginGoogleVerify = '/V3/UCenter/LoginVerify/googleVerify' //用谷歌验证码二次登录
  static bindMobileCode = '/V3/UCenter/User/bindMobileCode' //发送绑定短信
  static changeMobileCode = '/V3/UCenter/User/changeMobileCode' //发送换绑短信
  static bindMobile = '/V3/UCenter/User/bindMobile' //绑定手机
  static updateMobile = '/V3/UCenter/User/changeMobile' //修改绑定手机
  static loginMobileCode = '/V3/UCenter/LoginVerify/mobileCode' //统一登录短信码
  static loginEmailCode = '/V3/UCenter/LoginVerify/emailCode' //统一登录邮箱码
  static bindEmail = '/V3/UCenter/User/bindEmail' //绑定邮箱
  static bindEmailCode = '/V3/UCenter/User/bindEmailCode' //发送绑定邮件
  static verifyEmailCode = '/V3/UCenter/User/verifyEmailCode' //发送安全项变更邮件
  static verifyMobileCode = '/V3/UCenter/User/verifyMobileCode' //发送安全项变更短信
  static getGoogleSecret = '/V3/UCenter/User/bindGoogleCode' //登录状态获取谷歌绑定密钥
  static transferEmailCode = '/V3/UCenter/User/transferEmailCode' //发送提币转账邮件
  static transferMobileCode = '/V3/UCenter/User/transferMobileCode' //发送提币转账短信
  static bindGoogleVerify = '/V3/UCenter/User/bindGoogle' //绑定谷歌密钥
  static updateGoogleVerify = '/V3/UCenter/User/changeGoogle' //修改谷歌密钥
  static updatePassword = '/V3/UCenter/User/updatePassword' //修改登录密码
  static closeGoogleVerify = '/V3/UCenter/User/closeGoogle' //关闭谷歌密钥
  static openGoogleVerify = '/V3/UCenter/User/openGoogle' //开启谷歌密钥
  static setTradePwd = '/V3/UCenter/User/setTradePwd' //设置交易密码
  static verifyTradePwd = '/V3/UCenter/User/checkTradePwd' //验证交易密码
  static resetTradePwd = '/V3/UCenter/User/resetTradePwd' //重置交易密码
  static setBioTradePwd = '/V3/UCenter/User/setWithoutPwd' //设置免密支付ID
  static closeSecondVerification = '/V3/UCenter/User/closeSecondVerification' //关闭支付二次验证
  static openSecondVerification = '/V3/UCenter/User/openSecondVerification' //开启支付二次验证
  static universalForget = '/V3/UCenter/Forget/universal' //三合一忘记密码找回
  static submitForgetUniversal = '/V3/UCenter/Forget/verifyUniversal' //三合一忘记密码提交
  static sendForgetPwdEmailCode = '/V3/UCenter/Forget/email' //找回密码，发送邮件
  static sendForgetPwdMobileCode = '/V3/UCenter/Forget/sms' //找回密码，发送短信
  static forgetCheckUpdate1 = '/V3/UCenter/Forget/resetPwd' //重置密码
  static deleteAccount = '/V3/UCenter/User/del' //账号删除
  static openMailNotice = '/V3/UCenter/UserDevices/openMailNotice' //开启新设备登录提醒
  static emailDeviceList = '/V3/UCenter/UserDevices/list' //设备列表
  static removeDevice = '/V3/UCenter/UserDevices/destroy' //删除设备
  static closeMailNotice = '/V3/UCenter/UserDevices/closeMailNotice' //关闭新设备登录提醒

  static getUserInfo = '/V3/UCenter/User/getInfo' //获取用户信息
  /** 获取用户信息 - v4 */
  static getUserInfoV4 = '/V4/UCenter/User/getInfo'
  static getInitInfo = '/Publics/getWebInitInfo' //获取用户初始化信息
  static updateUserInfo = '/UCenter/Users/updateUserInfo' //修改用户信息:头像昵称简介
  /** V4 修改用户信息 */
  static updateUserInfoV4 = '/V4/UCenter/User/update'
  /** 个人中心功能列表 */
  static userCenterConfig = '/V4/UserCenter/UserCenterConfig/index'
  /** 更新常用功能列表接口 */
  static updateUserCenterFastConfig = '/V4/UserCenter/UserCenterFastConfig/update'
  /** 安全验证 - 谷歌验证 */
  static safetyVerifyGoogle = '/V4/UCenter/User/safetyVerifyGoogle'
  /** 安全验证 - 手机验证 */
  static safetyVerifyMobile = '/V4/UCenter/User/safetyVerifyMobile'
  /** 安全验证 - 邮箱验证 */
  static safetyVerifyEmail = '/V4/UCenter/User/safetyVerifyEmail'
  /** 个人中心 - 获取绑定手机的验证码 */
  static bindMobileCodeV4 = '/V4/UCenter/User/bindMobileCode'
  /** 个人中心 - 获取换绑绑定手机的验证码 */
  static changeMobileCodeV4 = '/V4/UCenter/User/changeMobileCode'
  /** 个人中心 - 绑定手机 */
  static bindMobileV4 = '/V4/UCenter/User/bindMobile'
  /** 个人中心 - 换绑手机 */
  static changeBindMobileV4 = '/V4/UCenter/User/changeMobile'
  /** 个人中心 - 获取绑定邮箱的验证码 */
  static bindEmailCodeV4 = '/V4/UCenter/User/bindEmailCode'
  /** 个人中心 - 获取换绑邮箱验证码 */
  static changeEmailCodeV4 = '/V4/UCenter/User/changeEmailCode'
  /** 个人中心 - 绑定邮箱*/
  static bindEmailV4 = '/V4/UCenter/User/bindEmail'
  /** 个人中心 - 换绑邮箱 */
  static changeBindEmailV4 = '/V4/UCenter/User/changeEmail'
  /** 个人中心 - 安全验证 获取邮箱验证码 */
  static getVerifyEmailCode = '/V4/UCenter/User/verifyEmailCode'
  /** 个人中心 - 安全验证 获取手机验证码 */
  static getVerifyMobileCode = '/V4/UCenter/User/verifyMobileCode'
  /** 个人中心 - 谷歌验证器绑定 */
  static bindGoogleV4 = '/V4/UCenter/User/bindGoogle'
  /** 个人中心 - 谷歌换绑 */
  static changeBindGoogleV4 = '/V4/UCenter/User/changeGoogle'
  /** 个人中心 - 获取谷歌验证器令牌 */
  static getGoogleSecretV4 = '/V4/UCenter/User/bindGoogleCode'
  /** 修改密码 - v4 */
  static updatePasswordV4 = '/V4/UCenter/User/updatePassword'
  /** 设置支付密码 - v4 */
  static setTradePasswordV4 = '/V4/UCenter/User/setTradePwd'
  /** 重置支付密码 - v4 */
  static resetTradePasswordV4 = '/V4/UCenter/User/resetTradePwd'
  /** 开启谷歌验证 - v4 */
  static openGoogleV4 = '/V4/UCenter/User/openGoogle'
  /** 关闭谷歌验证 - v4 */
  static closeGoogleV4 = '/V4/UCenter/User/closeGoogle'
  /** 获取注册手机验证码 */
  static getRegisterPhoneCode = '/V4/UCenter/Register/mobileCode'
  /** 获取注册邮箱验证码 */
  static getRegisterEmailCode = '/V4/UCenter/Register/emailCode'
  /** 检查手机是否存在 */
  static checkMobile = '/V4/UCenter/Register/checkMobile'
  /** 检测邮箱是否存在 */
  static checkEmail = '/V4/UCenter/Register/checkEmail'
  /** 手机注册 */
  static registerByMobileV4 = '/V4/UCenter/Register/mobile'
  /** 邮箱注册v4 */
  static registerByEmailV4 = '/V4/UCenter/Register/email'
  /** 检查手机验证码 */
  static checkMobileCode = '/V4/UCenter/Register/checkMobileCode'
  /** 检查邮箱验证码 */
  static checkEmailCode = '/V4/UCenter/Register/checkEmailCode'
  /** 检查登录用户是否存在 */
  static checkLoginName = '/V4/UCenter/Login/checkLoginName'
  /** 检查账号和密码是否匹配 */
  static checkPassword = '/V4/UCenter/Login/checkPassword'
  /** 安全模块校验 */
  static checkSafetyVerify = '/V4/UCenter/Login/checkSafetyVerify'
  /** 登录 */
  static loginV4 = '/V4/UCenter/Login/universal'
  /** 退出登录 */
  static logoutV4 = '/V4/UCenter/User/loginOut'
  /** 找回密码 */
  static checkUniversal = '/V4/UCenter/Forget/checkUniversal'
  /** 忘记密码 - 安全模块验证 */
  static checkForgetSafetyVerify = '/V4/UCenter/Forget/checkSafetyVerify'
  /** 忘记密码 重置密码 */
  static resetPasswordByForget = '/V4/UCenter/Forget/resetPwd'

  static get24HourPayLimitNum = '/UCenter/Security/get24HourPayNum' //获取24小时转出额度和限额
  static paySecondVerificationSwitch = '/V3/UCenter/User/updateSecondVerification' //支付二次验证开关API
  // static  checkTransfer = "/UCenter/Users/checkTransfer"; //支付二次验证剩余额度
  static checkNeedTwiceVerify = '/UCenter/Security/checkNeedTwiceVerify' //检测是否需要二次验证

  static mintGetProductList = '/Publics/getUnionProductList' //挖矿产品列表页
  static deFiGetProductInfo = '/Publics/getDeFiProductInfo' //DeFi产品详情页(有用户态)
  static cloudGetProductInfo = '/Publics/getCloudProductInfo' //云矿机产品详情页(有用户态)

  static cloudGetPositionInfo = '/CloudMining/getPositionInfo' //云矿机产品持仓详情
  static deFiBuy = '/DeFi/buy' //购买DeFi产品
  static cloudMiningBuy = '/CloudMining/buy' //购买挖矿产品
  static deFiSell = '/DeFi/sell' //赎回DeFi产品
  static deFiGetPositionInfo = '/DeFi/getPositionInfo' //DeFi产品持仓详情

  static bindDevice = '/App/Common/bindDevice' //用户绑定设备ID，谷歌推送用
  static searchKeywordLog = '/Common/search' //用户搜索上传

  static messageSetting = '/App/Common/messageSetting' //app用户设置是否开启系统消息通知
  static readMessage = '/App/Common/readMessage' //读取消息
  static getMessageList = '/App/Common/getMessageList' //获取站内信列表
  static promptGrabRedCode = '/RedCode/Trace/promptGrabRedCode' // 获取口令对应的红包
  static grabRedCode = '/RedCode/Action/grabRedCode' // 开红包、抢红包
  static getRedPacketInfo = '/RedCode/Action/getRedPacketInfo' // 获取口令对应的红包详情

  static getStaticTablesVersion = '/Settings/getStaticTableList' //获取静态表版本
  static getStaticFilesVersion = '/Settings/getStaticFileList' //获取下载文件版本
  static getStaticTableInfoList = '/Settings/getStaticTableInfoList' //获取指定的若干个静态表
  static getStaticTableInfo = '/Settings/getStaticTableInfo' //获取指定的1个静态表
  static getBackupServers = '/Settings/baseConfigUrl' //获取备份服务器
  static periodicRefresh = '/Merge/common' //公共接口整合 用于轮询

  // static  getAssets = "/UCenter/Assets/getAssetsListV3"; //获取资产列表
  // static  getAllAssets = "/UCenter/Assets/getAllAssets"; //获取所有资产
  static getAllAssetsV2 = '/AssetsV2/getAssetsV3' //获取所有资产
  static getAnalysisAssets = '/App/Analysis/assets' //获取资产分析
  static getExchangeInfo = '/App/Assets/getExchangeInfo' //获取链上转账记录详情
  static swapAndInTransfer = '/UCenter/Assets/swapAndInTransfer' //换汇加内部划转
  static getAssetsLogList = '/SecretOtc/App/getAssetsLog' //获取账单列表
  static setAssetsLogRemark = '/SecretOtc/App/setAssetsLogRemark' //设置充提币备注
  static getAssetsInfo = '/SecretOtc/App/getAssetsInfo' //获取账单详情
  static parseWalletQRCode = '/SecretOtc/App/matchWallet' //解析钱包二维码
  static getHomeData = '/Publics/getMainV2' //获取首页数据
  static getHomeLoginGuides = '/Api/Api/loginGuides' //获取首页登陆引导文案
  static getHomeBanner = '/Api/Api/banner' //获取首页banner数据
  static getHomeFreshmanTask = '/api/homepage/tasks' //获取首页新手任务
  static homeFreshmanTaskClaimAward = '/api/homepage/task/claim' //首页新手任务领取积分
  static getPromotionsList = '/Api/Api/activities' //获取活动列表

  static getCashExchangePriceListLogin = '/SecretOtc/App/getCurrencyList' //获取汇兑实时汇率
  static getCashExchangePriceList = '/SecretOtc/Common/getCurrencyList' //获取汇兑实时汇率

  static addOrDeleteFavirateTradePairs = '/Common/addOptionV2' //自选交易对
  static batchModifyFavirateTradePairs = '/Common/replaceOption' //批量修改自选交易对
  static swapAndOutTransfer = '/UCenter/Assets/swapAndOutTransfer' //换汇加区块链转账
  static getlockAssetsOrderList = '/AssetsV2/getlockAssetsOrderList' //获取指定币种冻结资产的委托单
  static getAssetsNewInfo = '/AssetsV2/getNewInfo' //资产页面获取信息

  static getUserExchangeLimit = '/UCenter/Users/getUserExchangeLimit' //提币额度 二十四小时提币数量

  static sendRedBag = '/RedPacket/Action/sendRedPacket' //发红包
  static grabRedBag = '/RedPacket/Action/grabRedPacket' //抢红包
  static redBagDetail = '/RedPacket/Trace/packetInfo' //红包详情

  static getCoinsList = '/Settings/itemList' //获取币种列表
  static getCoinRate = '/Settings/getRateList' //获取各个币种汇率
  // static  getCashExchangeRate = "/Settings/getRate"; //获取外汇交易汇率

  static swapNeedItemList = '/UCenter/Assets/swapNeedItemV2List' //换汇需要的参数

  static getCoinInAddressList = '/UCenter/Address/getFinanceAddressList' //获取充币地址列表
  static createFinanceAddress = '/UCenter/Address/createFinanceAddress' //创建财务充币地址
  static saveFinanceAddress = '/UCenter/Address/saveFinanceAddress' //设置财务地址信息
  static delFinanceAddress = '/UCenter/Address/delFinanceAddress' //删除充币地址
  static getCoinOutAddressesList = '/UCenter/Address/addressList' //获取用户提币地址列表
  static deleteCoinOutAddress = '/UCenter/Address/delAddress' //删除提币地址
  static addCoinOutAddress = '/UCenter/Address/addAddress' //添加提币地址
  static getUserInfoByAddress = '/UCenter/Address/getUserInfoByAddress' //根据钱包地址获取用户信息

  // static  secretOtcBuyAdList = "/Publics/adList"; //匿名卖币获取广告列表
  static secretOtcAdList = '/Publics/adList' //匿名买卖币获取广告列表

  // static  secretOtcHistoryOrderList = "/SecretOtc/App/getHistoryOrderList"; //获取sotc历史订单
  static secretOtcHistoryOrderList = '/SecretOtcV2/AppOrder/userHistoryOrder' //获取sotc历史订单
  static thirdOtcHistoryOrderList = '/ThirdTrade/Trade/orderList' //获取第三方otc历史订单
  // static  secretOtcOrderList = "/SecretOtc/App/getUserOrderList"; //获取sotc进行中的订单
  static secretOtcOrderList = '/SecretOtcV2/AppOrder/roomIngOrder' //获取sotc进行中的订单
  static secretOtcCancelOrder = '/SecretOtcV2/AppOrder/appUserCancelOrder' //sotc用户取消订单
  // static  secretOtcUserCancelBuyOrder = "/SecretOtcV2/AppOrder/doSellCancel"; //sotc用户取消订单
  // static  secretOtcUserCancelSellOrder = "/SecretOtcV2/AppOrder/doBuyCancel"; //匿名卖币用户取消订单
  static secretOtcUserCreateBuyOrder = '/SecretOtcV2/App/doSellPlace' //sotc用户下单
  static secretOtcUserCreateSellOrder = '/SecretOtcV2/App/doBuyPlace' //匿名卖币用户下单
  static secretOtcCreateUserMatchOrder = '/SecretOtcV2/AppOrder/createQuickOrder' //sotc快捷下单 创建用户匹配单
  static secretOtcCancelUserMatchOrder = '/SecretOtcV2/AppOrder/cancelQuickMasterOrder' //sotc快捷下单 取消用户匹配单
  static secretOtcUserComplaint = '/SecretOtcV2/AppOrder/appUserComplaint' //Sotc用户申诉
  static secretOtcUserCancelComplaint = '/SecretOtcV2/AppOrder/appUserCancelComplaint' //Sotc用户取消申诉
  static secretOtcUserPromptOrder = '/SecretOtcV2/AppOrder/userPromptOrder' //Sotc用户催单
  // static  secretOtcUserIssuingCard = "/SecretOtcV2/AppOrder/issuingCard"; //Sotc用户出售发卡,也就是接单
  static secretOtcUserIssuingCard = '/SecretOtcV2/AppOrder/appUserSendCard' //Sotc用户出售发卡,也就是接单
  static secretOtcMerchantAcceptBuyOrder = '/SecretOtcV2/AppOrder/AppUserReceiveOrder' //Sotc商户购买接单
  // static  secretOtcUserSellOrderDoReceived = "/SecretOtcV2/AppOrder/doReceived"; //匿名卖币用户确认放币
  static secretOtcUserSellOrderDoReceived = '/SecretOtcV2/AppOrder/appUserCompleteOrder' //匿名卖币用户确认放币
  static secretOtcGetBuyChatWs = '/SecretOtc/App/getChatWs' //获取匿名otc买入聊天webSocket
  static secretOtcGetSellChatWs = '/SecretOtc/Buy/App/getChatWs' //获取匿名otc卖出聊天webSocket
  static getClientChat = '/App/Gm/getChatWs' //获取客服聊天webSocket
  static getCustomerServiceChatWs = '/Settings/getCustomerServiceChatWs' //未登录时 获取客服聊天webSocket
  // static  secretOtcUploadMultiplePaymentVoucher = "/SecretOtc/App/uploadPaymentVoucherV2"; //上传多张付款凭证
  static secretOtcUploadMultiplePaymentVoucher = '/SecretOtcV2/AppOrder/appUserUpPayVoucher' //上传多张付款凭证
  // static  secretOtcHistoryChatList = "/SecretOtc/App/historyChatList"; //聊天记录列表
  static secretOtcHistoryChatList = '/SecretOtcV2/AppOrder/userHistoryRoomList' //聊天记录列表
  static secretOtcStatisticsInfo = '/SecretOtc/App/rightInfo' //统计信息(进行中订单/未读消息)
  static secretOtcAdInfo = '/SecretOtcV2/App/adInfo' //获取广告详情
  static secretOtcMatchQuickTradeAreaMerchant = '/SecretOtcV2/App/quickTradeArea' //快捷交易区匹配商户
  static secretOtcMatchQuickTradeLimit = '/SecretOtcV2/App/quickTradeLimit' //快速匹配金额和数量范围
  static getSecretOtcOrderDetail = '/SecretOtcV2/AppOrder/appUserOrderInfo' //获取订单详情
  static getSecretOtcMatchOrderDetail = '/SecretOtcV2/AppOrder/quickMasterOrderInfo' //获取匹配订单详情
  static secretOtcOrderSaveRemark = '/SecretOtc/App/saveAppRemark' //保存sotc订单备注
  static secretOtcSavePayment = '/SecretOtc/Buy/App/savePayment' //添加收款方式
  static secretOtcEditPayment = '/SecretOtc/Buy/App/editPayment' //编辑收款方式
  static secretOtcDelPayment = '/SecretOtc/Buy/App/delPayment' //删除收款方式
  static secretOtcTopPayment = '/SecretOtc/Buy/App/topPayment' //置顶收款方式
  static secretOtcGetMyPaymentList = '/SecretOtc/Buy/App/getMyPayment' //收款方式列表
  static secretOtcSetAccountCeiling = '/SecretOtcV2/App/setAssistPayment' //为支付账户设置限额和附言

  static secretOtcGetMerchantAdList = '/SecretOtcV2/Trade/getAdList' //获取商户广告列表
  static secretOtcMerchantGetSellConf = '/SecretOtcV2/Trade/getSellConf' //获取商户出售配置
  static secretOtcMerchantGetBuyConf = '/SecretOtcV2/Trade/getBuyConf' //获取商户购买配置
  static secretOtcMerchantGetGuidePrice = '/SecretOtcV2/Web/getGuidePrice' //价格预览
  static secretOtcMerchantSetBuyConf = '/SecretOtcV2/Trade/setBuyConf' //商户购买
  static secretOtcMerchantSetSellConf = '/SecretOtcV2/Trade/setSellConf' //商户出售
  static secretOtcMerchantUpdateOnlineStatus = '/SecretOtcV2/Trade/updateOnlineStatus' //更新广告状态

  static secretOtcSdwMatchMerchantInfo = '/SecretOtc/Sdw/Recharge/matchMerchantInfo' //充值提现匹配商户
  static secretOtcSdwRechageDoPlace = '/SecretOtc/Sdw/Recharge/doPlace' //充值下订单
  // static  secretOtcSdwWithdrawDoPlace = "/SecretOtc/Sdw/Withdraw/doPlace"; //提现下订单
  static platformRechargeOrder = '/ThirdTrade/Trade/platformRechargeOrder' //第三方充值
  static platformWithdrawOrder = '/ThirdTrade/Trade/platformWithdrawOrder' //第三方卖币
  static thirdTradeGetPrice = '/ThirdTrade/Trade/getPrice' //第三方交易询价
  static swapAndWithdraw = '/UCenter/Assets/swapAndWithdraw' //换汇加提现下订单
  static secretOtcSdwCheckWithdrawOrder = '/SecretOtc/App/isExchangeOrder' //查询是否有进行中的提现订单
  static getSecretOtcCoinRate = '/SecretOtc/App/sotcPriceList' //获取Sotc汇率

  static revokeChatMessage = '/SecretOtc/App/revokeMessage' //聊天撤回消息

  static cashExchangeRateKLineLogin = '/SecretOtc/ForeignExchange/kline' //换汇汇率k线
  static cashExchangeRateKLine = '/SecretOtc/Common/kline' //换汇汇率k线

  static cashExchangeCreateOrder = '/SecretOtc/ForeignExchange/swap' //换汇下单

  // 理财产品列表API
  // static  financeProductList = "/App/Finances/getProductList"; //理财产品列表
  static financeProductList = '/Publics/getFinanceList' //理财产品列表
  static getProductInfo = '/Publics/getFinanceProductInfo' //获取理财产品详情
  static getProductInfoLogin = '/App/Finances/getProductInfo' //获取理财产品详情

  static buyFinanceProduct = '/App/Finances/buy' //购买理财产品
  static sellFinanceProduct = '/App/Finances/sell' //赎回理财产品
  static getPositionInfo = '/App/Finances/getPositionInfo' //用户持仓详情
  static getTurnoverList = '/App/Finances/getTurnoverList' //获取用户持仓资产流水列表
  static getPositionList = '/App/Finances/getPositionList' //获取用户理财持仓
  static getPositionListV2 = '/App/Finances/getPositionListV2' //获取用户理财资产
  static getUserAutoBuyProductList = '/App/Finances/userAutoBuyProduct' //获取自动存入理财产品列表
  static setUserAutoBuyFinances = '/App/Finances/autoBuySwitch' //设置是否开启自动存入理财产品
  static historyPositionList = '/App/Finances/historyPositionList' //理财产品持仓历史列表

  //卡券
  static getUserCard = '/api/voucher/userVenders' //获取用户卡券列表
  static voucherClaim = '/api/voucher/claim' //领取卡券

  //币币交易
  // static  getCoinMarketList = "/App/TradeCoin/getTradePairList"; //币币交易获取行情
  static getCoinMarketListV2 = '/MarketV2/getSpotList' //币币交易获取行情
  static getLimitOrders = '/Spot/Trade/trustIndex' //用户委托列表
  static trustStore = '/Spot/Trade/trustStore' //委托下单
  static cancelLimitOrder = '/Spot/Trade/trustCancel' //撤销委托单
  static getFinishOrders = '/Spot/Trade/trustDealIndex' //成交单列表
  static coinOrderDetail = '/Spot/Trade/trustShow' //用户委托详情

  static switchCoin = '/App/TradeCoin/switchCoin' //币币交易买卖
  // static  getOrderList = "/App/TradeCoin/getOrderList"; //获取币币订单
  static getOrderListV2 = '/App/TradeCoin/getOrderListV2' //获取币币订单
  static limitOrder = '/App/TradeCoin/limitOrder' //币币委托挂单
  // static  cancelLimitOrder = "/App/TradeCoin/cancelLimitOrder"; //撤销委托单
  static getSelfSelectList = '/Common/getOptionListV2' //获取自选交易对数据

  //杠杆交易
  static getMarginSymbolConfig = '/Margin/marginSymbolConfig' //用户交易对杠杆配置
  static openPosition = '/Margin/openPosition' //开仓
  static openPositionList = '/Margin/openPositionList' //持仓列表 (开仓 持仓)
  static appendInterest = '/Margin/appendInterest' //追加本金
  static marginPositionList = '/Assets/marginPositionList' //资产总览页 杠杆仓位列表
  static marginPositionInfo = '/Margin/positionInfo' //仓位详情
  static closePosition = '/Margin/closePosition' //用户主动平仓
  static setPositionProfitLoss = '/Margin/setPositionProfitLoss' //设置仓位止盈止损
  static setPlanProfitLoss = '/Margin/setPlanProfitLoss' //设置触犯单止盈止损
  static getMarginMarkets = '/MarketV2/getMarginList' //超级杠杆行情

  static marginAssetsV2 = '/Assets/marginAssetsV2' //杠杆总资产 加资产明细
  static getLeverOrderInfo = '/Assets/getLeverOrderInfo' //杠杆交易订单详情
  static allClosePosition = '/Margin/allClosePosition' //一键全平
  static uMarginMerge = '/Merge/margin' //超级杠杆接口整合

  //合约
  // static  getContractMarketList = "/Contract/getTradeList"; //合约交易对行情
  static getContractMarketListV2 = '/MarketV2/getContractList' //合约交易对行情
  // static  getContractSymbolConfig = "/Contract/symbolConfig"; //获取合约交易对信息
  static contractOpenPosition = '/Contract/openPosition' //开仓
  static contractOpenPositionList = '/Contract/openPositionList' //持仓列表
  static contractAllClosePosition = '/Contract/allClosePosition' //全平
  static contractPositionInfo = '/Contract/positionInfo' //合约仓位详情
  // static  contractPlanOrder = "/Contract/planOrder"; //合约计划委托
  static contractPlanOrderList = '/Contract/planOrderList' //合约触发价订单列表
  static contractRevokePlan = '/Contract/revokePlan' //合约撤销计划委托
  static contractAppendInterest = '/Contract/appendInterest' //合约追加保证金
  // static  contractSetPositionProfitLoss = "/Contract/setPositionProfitLoss"; //合约仓位止盈止损
  static contractSetPlanProfitLoss = '/Contract/setPlanProfitLoss' //合约委托单止盈止损
  static contractAssets = '/Assets/contractAssets' //合约资产
  static contractGetFundingRate = '/Contract/getFundingRate' //资金费率
  static revokeAllPlan = '/Contract/revokeAllPlan' //撤销所有计划委托
  static uContractMerge = '/Merge/contract' //U本位合约接口整合
  static uContractClosePartPosition = '/Contract/Trade/closePartPosition' //U本位合约 部分平仓操作
  static contractOpenPositionNew = '/Contract/Trade/openPosition' //U本位开仓 支持止盈止损开仓
  static contractStopPlanOrder = '/Contract/Trade/planOrder' //发布计划委托 止盈止损
  static contractStopOrderList = '/Contract/Common/stopOrderList' //止盈止损订单列表
  static contractCancelStopMarket = '/Contract/StopMarket/cancelStopMarket' //撤销止盈止损订单
  static contractPositionPushStopMarket = '/Contract/StopMarket/pushStopMarket' //发布止盈止损订单
  static contractAllCancelStopMarket = '/Contract/StopMarket/allCancelStopMarket' //撤销所有止盈止损
  static contractLimitOrderList = '/Contract/Common/limitOrderList' //限价委托列表
  static contractOpenLimitOrder = '/Contract/Trade/limitOrder' //U本位合约 发布现价委托
  static contractrevokeLimitOrder = '/Contract/Trade/revokeLimitOrder' //U本位合约 撤销现价订单
  static contractSwitchPositionMode = '/Contract/Trade/switchPositionMode' //U本位 设置仓位模型
  static contractCrossModeList = '/Contract/Common/crossModeList' //U本位 仓位模型列表
  static contractVoucherList = '/Contract/Common/getVoucherList' //U本位用户的赠金列表
  static contractVoucherOpenPosition = '/Contract/VoucherTrade/openPosition' //合约赠金劵开仓

  //////////U本位模拟盘
  static contractOpenPositionFake = '/Fake/Contract/Trade/openPosition' //开仓
  static contractOpenPositionListFake = '/Fake/Contract/Common/openPositionList' //持仓列表
  static contractAllClosePositionFake = '/Fake/Contract/Trade/allClosePosition' //全平
  static contractPositionInfoFake = '/Fake/Contract/Common/positionInfo' //合约仓位详情
  static contractPlanOrderListFake = '/Fake/Contract/Common/planOrderList' //合约触发价订单列表
  static contractRevokePlanFake = '/Fake/Contract/PlanTrade/revokePlan' //合约撤销计划委托
  static contractAppendInterestFake = '/Fake/Contract/Trade/appendBailNumber' //合约追加保证金
  static contractSetPlanProfitLossFake = '/Fake/Contract/StopMarket/setPlanProfitLoss' //合约委托单止盈止损
  static contractAssetsFake = '/Fake/Contract/Common/contractAssets' //合约资产 //todo
  static contractGetFundingRateFake = '/Fake/Contract/Common/getFundingRate' //资金费率
  static revokeAllPlanFake = '/Fake/Contract/PlanTrade/revokeAllPlan' //撤销所有计划委托
  static uContractClosePartPositionFake = '/Fake/Contract/Trade/closePartPosition' //U本位合约 部分平仓操作
  static contractStopPlanOrderFake = '/Fake/Contract/PlanTrade/planOrder' //发布计划委托 止盈止损
  static contractStopOrderListFake = '/Fake/Contract/Common/stopOrderList' //止盈止损订单列表
  static contractCancelStopMarketFake = '/Fake/Contract/StopMarket/cancelStopMarket' //撤销止盈止损订单
  static contractPositionPushStopMarketFake = '/Fake/Contract/StopMarket/pushStopMarket' //发布止盈止损订单
  static contractAllCancelStopMarketFake = '/Fake/Contract/StopMarket/allCancelStopMarket' //撤销所有止盈止损
  static contractLimitOrderListFake = '/Fake/Contract/Common/limitOrderList' //限价委托列表
  static contractOpenLimitOrderFake = '/Fake/Contract/LimitTrade/limitOrder' //U本位合约 发布现价委托
  static contractrevokeLimitOrderFake = '/Fake/Contract/LimitTrade/revokeLimitOrder' //U本位合约 撤销现价订单
  static getKlineFake = '/Lever/kline' //k线数据 //todo
  static contractAddAssetsFake = '/Fake/FakeAssets/apply' //申请模拟资产
  static contractAddAssetsFake2 = '/Fake/FakeAssets/applyV2' //申请模拟资产2
  static contractAssetsListFake = '/Fake/FakeAssets/assetsList' //模拟盘资产列表
  static contractSwitchPositionModeFake = '/Fake/Contract/Trade/switchPositionMode' //模拟盘 设置仓位模型
  static contractCrossModeListFake = '/Fake/Contract/Common/crossModeList' //模拟盘合约全仓模式 交易对

  //////////币本位
  static contractFuturesList = '/MarketV2/contractFuturesList' //合约 币本位/U本位 行情列表
  static openPositionCoinBase = '/Futures/Trade/openPosition' //合约币本位合约建仓
  static openPositionListCoinBase = '/Futures/Common/openPositionList' //合约币本位 持仓列表 (开仓 持仓)
  static futuresPlanOrder = '/Futures/Trade/planOrder' //币本位合约计划委托
  static futuresPlanOrderList = '/Futures/Common/planOrderList' //币本位合约触发价订单列表
  static futuresClosePosition = '/Futures/Trade/closePosition' //币本位合约平仓
  static futuresAllClosePosition = '/Futures/Trade/allClosePosition' //全平
  static futuresRevokePlan = '/Futures/Trade/revokePlan' //币本位合约撤销计划委托
  static futuresAppendInterest = '/Futures/Trade/appendBailNumber' //币本位合约追加保证金
  static futuresSetPositionProfitLoss = '/Futures/Trade/setPositionProfitLoss' //币本位合约仓位止盈止损
  static futurePositionInfo = '/Futures/Common/positionInfo' //币本位合约仓位详情
  static futureSetPlanProfitLoss = '/Futures/Trade/setPlanProfitLoss' //币本位合约委托单止盈止损
  static futureRevokeAllPlan = '/Futures/Trade/revokeAllPlan' //撤销所有计划委托
  static futureGetFundingRate = '/Futures/Trace/getFundingRate' //资金费率
  static uFuturestMerge = '/Merge/futures' //币本位合约接口整合
  static futureSwitchPositionMode = '/Futures/Trade/switchPositionMode' //币本位 设置仓位模型
  static futureCrossModeList = '/Futures/Common/crossModeList' //币本位 仓位模型列表

  static contractPriceList = '/Lever/marketPriceList' //合约行情交易对展示
  static getDepth = '/Lever/depth' //深度
  static getKline = '/Lever/kline' //k线数据
  static getCoinInfo = '/App/Product/getCoinInfo' //获取币种详情
  static getDataFlow = '/Lever/dataflow' //获取资金流向分析
  static getOrderBook = '/Web/Kline/orderbook' //获取成交记录

  static getDfDepth = '/pub/depth' //双仓合约深度
  static getDfKline = '/pub/kline' //双仓合约 K线
  static getDfMarket = '/pub/ticker' //双仓合约 行情列表

  static planOrder = '/Margin/planOrder' //计划委托
  static planOrderList = '/Margin/planOrderList' //触发价订单列表
  static revokePlan = '/Margin/revokePlan' //撤销计划委托

  //双仓合约
  static dcontractOpenPosition = '/api/order' //下单
  static dcontractOpenStopOrder = '/api/stopOrder' //发布订单止盈止损下单
  static dcontractStaticConfig = '/pub/exchangeInfo' //获取交易规则和交易对
  static dcontractAccount = '/assets/account' //账户信息,仓位信息
  static dcontractOpenOrders = '/api/getOpenOrders' //查看当前全部挂单
  static dcontractCancelOrder = '/api/cancel' //撤销委托单
  static dcontractChangeBail = '/api/positionMargin' //调整保证金
  static dcontractHistoryOrder = '/api/getAllOrders' //查询历史委托单
  static dcontractHistoryPosition = '/api/getClosePositions' //获取已平仓仓位列表
  static dcontractGetFundingRate = '/pub/premiumIndex' //资金费率
  static dcontractAggrement = '/api/openFutures' //开通合约
  static dcontractChangeLeverage = '/api/leverage' //调整杠杆
  static dcontractTradeDetailHistory = '/api/getTradeHistory' //查询历史成交记录
  static dcontractFundlog = '/assets/fundLog' //资金流水
  static dcontractChangeMarginType = '/api/marginType' //修改仓位保证金模式
  static useBonusVoucher = '/api/useBonusVoucher' //使用赠金
  static dcontractsStopPartOrder = '/api/stopPartOrder' //部分止盈止损委托 (仓位)

  //跟单双仓合约
  static followdcontractOpenPosition = '/cta/order' //下单
  static followdcontractOpenStopOrder = '/cta/stopOrder' //发布订单止盈止损下单
  static leaderdcontractAccount = '/cta/account' //账户信息,仓位信息
  static followdcontractAccount = '/cfa/account' //跟单用户 - 账户信息
  static followdcontractOpenOrders = '/cta/getOpenOrders' //查看当前全部挂单
  static followdcontractCancelOrder = '/cta/cancel' //撤销委托单
  static followdcontractChangeBail = '/cta/positionMargin' //调整保证金
  static followdcontractHistoryOrder = '/cta/getAllOrders' //查询历史委托单
  static followdcontractHistoryPosition = '/cta/getClosePositions' //获取已平仓仓位列表
  static followdcontractChangeLeverage = '/cta/leverage' //调整杠杆
  static followdcontractTradeDetailHistory = '/cta/getTradeHistory' //查询历史成交记录
  static followdcontractFundlog = '/cta/fundLog' //资金流水
  static followdcontractChangeMarginType = '/cta/marginType' //修改仓位保证金模式
  static followdcontractsStopPartOrder = '/cta/stopPartOrder' //部分止盈止损委托 (仓位)
  //借贷
  static getDebitTradeList = '/Debit/getTradeList' //获取借贷交易对
  static debitDoOrder = '/Debit/doOrder' //借贷下单
  static debitOrderList = '/Debit/orderList' //借贷订单列表
  static debitOrderInfo = '/Debit/orderInfo' //借贷订单详情
  static debitOrderHistory = '/Debit/orderDetail' //订单历史
  static doRepayment = '/Debit/doRepayment' //借贷还款
  static debitPosition = '/Debit/adjustPosition' //调整抵押金
  static debitSettlement = '/Debit/settlement' //手动结算
  static debitAssets = '/Debit/assets' //获取借贷资产

  //返佣
  static getRebateCommonInfo = '/V3/UserRebate/getRebateCommonInfo' //获取返佣公共信息
  static getInviteCodesList = '/V3/UserRebate/getInviteCodeStats' //获取邀请码列表
  static createInviteCode = '/V3/UserRebate/createInviteCode' //生成邀请码
  static updateInviteCode = '/V3/UserRebate/updateInviteCode' //修改邀请码
  static getFriendRebateStats = '/V3/UserRebate/getFriendRebateStats' //获取邀请好友返佣统计
  static getRebateList = '/V3/UserRebate/getRebateList' //获取返佣流水

  //========stock start ========
  static getStocktMarketList = '/Stock/market' //股票行情
  static stockDeal = '/api/stock/plat_order/store' // 下单接口
  static stockPositionList = '/api/stock/user_position/index' //用户持仓列表
  static stockPositionDetail = '/api/stock/user_position/info' //持仓详情
  static stockOrderList = '/api/stock/plat_order/index' //未成交列表
  static stockOrderDetail = '/api/stock/plat_order/info' //未成交详情
  static stockTradeOrderList = '/api/stock/user_order/index' //成交记录列表
  static stockTradeOrderDetail = '/api/stock/user_order/info' //成交记录详情
  static stockDepth = '/Stock/getData' // 股票深度+ orderBook
  static stockKLine = '/Stock/kline' // 股票K线
  static stockCancelOrder = '/api/stock/plat_order/cancel'
  static stockMarqueeList = '/Publics/marquee' //stock 交易 跑马灯
  // ======== stock end=========

  // ======== 跟单new start=========
  static homeCopytraderList = '/CopyTrading/Trace/mxBannerCopytrading' //首页交易员列表
  static derivshomeCopytraderList = '/CopyTrading/Trace/derivsBannerCopytrading' //衍生品-首页交易员列表
  static copytraderApply = '/CopyTrading/Trader/application' //申请成为带单达人
  static copytraderConfig = '/CopyTrading/Common/index' //获取跟单通用配置
  static copytraderBusinessConf = '/CopyTrading/Trader/businessConf' //多业务 带单配置 列表
  static copytraderSetBusinessConf = '/CopyTrading/Trader/setBusinessConf' //带单业务配置修改
  static copytraderCarousel = '/CopyTrading/Common/carousel' //banner

  static contractCopytraderList = '/CopyTrading/Trace/mxCopytrading' //双仓合约 - 带单交易员列表
  static contractCopytraderDetail = '/CopyTrading/Trace/mxTraderInfo' //双仓合约 - 交易员详情
  static contractMyTraders = '/follower/subAssetsList' //正在进行交易员列表
  static contractHisMyTraders = '/follower/hisFollowerRecord' //已结束交易员列表
  static contractCopy = '/follower/copyTrade' //跟随交易员且划转资产
  static mxFollowUserList = '/CopyTrading/Trace/mxFollowUserList' //跟随者列表
  static cumulativeIncome = '/CopyTrading/Trace/cumulativeIncome' //累计收益K线
  static dailyProfitLoss = '/CopyTrading/Trace/dailyProfitLoss' //每日盈亏K线
  static tradePreference = '/CopyTrading/Trace/tradePreference' //交易偏好统计图
  static incomeRateKline = '/CopyTrading/Trace/incomeRateKline' //累计收益率K线

  static derivscontractMyTraders = '/api/derivs/follower/myTrader' //衍生品 -正在进行交易员列表
  static derivscontractHisMyTraders = '/api/derivs/follower/myEndTrader' //衍生品 -已结束交易员列表
  static derivsCopy = '/api/derivs/follower/copyTrade' //跟随交易员且划转资产
  static derivsUpdateCopy = '/api/derivs/follower/updateCopyTrade' //修改跟随配置（

  static derivsCumulativeIncome = '/CopyTrading/Trace/derivsCumulativeIncome' //衍生品 -累计收益K线
  static derivsDailyProfitLoss = '/CopyTrading/Trace/derivsDailyProfitLoss' //衍生品 -每日盈亏K线
  static derivsTradePreference = '/CopyTrading/Trace/derivsTradePreference' //衍生品 -交易偏好统计图
  static derivsIncomeRateKline = '/CopyTrading/Trace/derivsIncomeRateKline' //衍生品 -累计收益率K线

  static derivsCopytraderList = '/CopyTrading/Trace/derivsCopytrading' //衍生品 - 带单交易员列表
  static derivsCopytraderDetail = '/CopyTrading/Trace/derivsTraderInfo' //衍生品 - 交易员详情
  static derivsMyTraders = '/CopyTrading/User/myDerivsTraders' //衍生品 - 我的交易员列表
  static derivsCancel = '/api/derivs/follower/cancel' //取消跟随

  static derivsMxFollowUserList = '/CopyTrading/Trace/derivsFollowUserList' //衍生品 -跟随者列表

  static followerCurrentPos = '/follower/position/list' //当前跟随仓位
  static followerHisPos = '/follower/position/history' //历史跟单仓位
  static followerAllHisPos = '/follower/closed/position' //历史跟单总仓位
  static leaderCurrentPos = '/leader/position/list' //当前带单仓位
  static leaderHisPos = '/leader/position/history' //历史带单仓位
  static followerAssets = '/follower/assets' //跟单用户合约收益
  static leaderAssets = '/leader/assets' //交易员合约收益
  static followerMyTrader = '/follower/myTrader' // 我的交易员

  static leaderCurrentDerivsPos = '/api/derivs/trader/positions' //衍生品 - 当前带单
  static leaderHisDerivsPos = '/api/derivs/trader/positionsHis' //衍生品 - 历史带单仓位
  static followerDerivsCurrentPos = '/api/derivs/follower/positions' //衍生品 - 当前跟随仓位
  static followerDerivsHisPos = '/api/derivs/follower/positionsHis' //历史跟单仓位
  static followerDerivsHisAllPos = '/api/derivs/follower/positionsHisAll' //衍生品 - 历史跟单仓位
  // static  followerDerivsAssets = "/api/derivs/follower/profit"; //衍生品 - 跟单用户收益
  static followerDerivsAssets = '/api/derivs/follower/account' //衍生品 - 跟单用户收益

  static leaderDerivsAssets = '/api/derivs/trader/profit' //衍生品 --交易员收益

  static leaderRabateDetail = '/leader/rebate/list' //每日分润仓位列表
  static leaderRabate = '/leader/rebate/details' //每日分润

  static leaderDerivsRabateDetail = '/api/derivs/trader/rebateList' //衍生品-每日分润仓位列表
  static leaderDerivsRabate = '/api/derivs/trader/rebateDetail' //衍生品-每日分润

  static followerCancel = '/follower/cancel' //撤销跟随关系
  static leaderCancel = '/leader/cancel' //取消交易员身份
  static leaderCancelOpinion = '/CopyTrading/Trader/cancelOpinion' //交易员撤销原因投诉
  static subscribeTrader = '/CopyTrading/User/subscribeTrader' //订阅交易员
  static leaderCloseTrade = '/leader/closeTrade' //结束跟单
  static derivsleaderCloseTrade = '/api/derivs/trader/close' //结束跟单

  static followerTransfer = '/follower/transfer' //跟随者划转资产
  static followerTransferLog = '/follower/transferLog' //跟随者 资产划转记录
  static leaderTransfer = '/leader/transfer' //交易员划转资产
  static leaderTransferLog = '/leader/transferLog' //交易员资产划转记录

  static derivsfollowerTransferLog = '/api/derivs/assets/transferLog' //衍生品-跟随者 划转记录
  static derivsLeaderTransferLog = '/api/derivs/trader/transferLog' //衍生品-交易员资产划转记录

  // ======== 跟单new end=========

  // ======== lang start=========
  static langsVersion = '/Lang/ClientVersion' //多语言版本号
  static langContent = '/Lang/ClientLangPackage' //多语言内容
  // ======== lang end=========

  // ======== 红包 start=========
  static redPacketInfo = '/RedPacket/Trace/packetGrabUserList' //当前红包领取记录
  static redPacketSend = '/RedPacket/Trace/historySendRedPacket' //历史发红包列表
  static redPacketReceiver = '/RedPacket/Trace/historyGrabRedPacket' //历史收红包列表
  // ======== 红包 end=========
  // ======== 聊天室 start=========
  static groupChatList = '/Chat/ChatCommon/chatList' //群聊列表
  static chatRoomDetail = '/Chat/GroupChat/getChatRoomShowInfo' //房间群消息详情
  static openGroupChatNotice = '/Chat/GroupChat/openNotice' //开启通知
  static closeGroupChatNotice = '/Chat/GroupChat/closeNotice' //关闭通知
  static exitGroupChat = '/Chat/GroupChat/exitGroupChat' //关闭通知
  static joinGroupChat = '/Chat/GroupChat/joinRoomRequest' //加入房间申请
  static getGroupChatInviteCode = '/Chat/GroupChat/getRequestUrl' //获取群邀请码
  static updateGroupChatInfo = '/Chat/GroupChat/setGroupChatInfo' //更新群名称/签名
  static chatRoomDetailByM = '/Chat/GroupChat/joinRoomInfo' //加群房间详情
  static revokeGroupChatMessage = '/Chat/GroupChat/revokeMessage' //撤回群消息
  static getGroupChatMessageList = '/Chat/GroupChat/getMessageList' //拉取群聊历史消息
  static getChatRoomUserList = '/Chat/GroupChat/getChatRoomUserList' //房间用户列表

  // ======== 聊天室 end=========
  // ======== 网页路径 start =========
  // static  webviewFee = "/#/fee-detail"; //费率标准
  static webviewStockManual = '/#/stock-guide' //股票交易指南
  static webviewLeverManual = '/#/trading-guide' //杠杆交易指南
  static webviewPrivacy = '/#/privacy-policy' //隐私政策
  static webviewStockLicence = '/#/business-compliance' //股票牌照
  static webviewCoinLicence = '/#/digital-currency' //数字货币牌照
  static userAgreement = '/#/user-agreement' //跟单用户协议

  //=====kyc
  static kycInfo = '/UCenter/Identity/kycInfo' //kyc 详情
  static applyKyc = '/UCenter/Identity/applyKyc' //KYC 申请
  static getRandom = '/UCenter/Identity/getIDRandNo' //KYC 申请
  static pushVideo = '/UCenter/Identity/pushVideo'
  static pushIdPhoto = '/UCenter/Identity/pushIdPhoto'
  static thirdKycInfo = '/UCenter/Kyc/kycIntroduction'

  static screenPop = '/SecretOtc/Common/getScreenPop' //开屏广告/弹窗 配置

  //=====元宇宙
  static metaverseTrend = '/Metaverse/marketTrend' //市场趋势
  static metaverseExpress = '/Metaverse/express' //元宇宙快讯
  //=========== 好友转账
  static friendsList = '/Friend/getFriendList' //获取好友列表
  static findUser = '/Friend/findUser' //搜索用户
  static addFriend = '/Friend/addFriend' //添加用户
  static deleteFriend = '/Friend/delFriend' //删除用户
  static strangeUser = '/Friend/strangeUser' //搜索用户
  static updateRemark = '/Friend/changeRemark' //搜索用户
  static friendTransfer = '/Friend/friendTransfer' //好友转账
  static userAddress = '/Friend/getUserAddress' //好友转账
  static getKycToken = '/UCenter/Kyc/getAssetsToken' //获取第三方kyc

  //第三方支付
  static thirdFast = '/ThirdPayment/fetchOtcUrl' //获取第三方支付地址

  //福利中心
  static giveawaysInfo = '/api/task/center' //任务中心页面
  static giveawaysSign = '/api/user/sign' //签到
  static giveawaysScoreHis = '/api/user/score/log' //积分记录
  static giveawaysReceive = '/api/task/receive' //领取任务奖励
  static giveawaysGoods = '/api/score/goods' //商品列表
  static giveawaysSwapHistory = '/api/exchange/log' //兑换记录
  static giveawaysSwap = '/api/goods/exchange' //商品列表
  //授权登录
  static getQRCodeInfo = '/V3/UCenter/ScanLogin/getQRCodeInfo' //获取二维码登录相关信息
  static authorizeLogin = '/V3/UCenter/ScanLogin/authorize' //授权登录
  //定投策略
  static autoInvestCreate = '/SpotStrategy/createRecurringBuyPlan' //创建定投策略
  static autoInvestList = '/SpotStrategy/userRecurringBuyPlanList' //币币策略计划列表
  static autoInvestHistoryList = '/SpotStrategy/recurringBuyPlanTradeList' //币币策略计划成交记录
  static autoInvestDetail = '/SpotStrategy/spotStrategyPlanDetail' //用户定投计划详情
  static strategyTradeSymbolLimit = '/SpotStrategy/symbolTradeLimit' //币币策略交易对交易限制信息
  static updateInvestStatus = '/SpotStrategy/updateRecurringPlanStatus' //更新定投计划状态

  static transferCoin = '/assets/transfer' //划转接口
  static transferLog = '/assets/transferLog' //划转记录接口
  //金融卡
  static cardStaticList = '/Monetary/Common/cardStaticList' //获取金融卡 静态卡数据
  static myCard = '/Monetary/User/myCard' //我的卡列表
  static applyCard = '/Monetary/User/applyCard' //申请开卡
  static applyCardWithoutUserInfo = '/Monetary/User/applyCardWithoutUserInfo' //申请开卡
  static applyCardRecord = '/Monetary/Trace/applyCardRecord' //开卡记录
  static transactionRecord = '/Monetary/Trace/transactionRecord' //交易记录
  static cardRecharge = '/Monetary/User/cardRecharge' //用户充值
  static cardInfo = '/Monetary/User/cardInfo' //查看卡片完整信息
  static getFeeRate = '/Monetary/User/getFeeRate' //获取手续费率
  static cancelCard = '/Monetary/User/cancelCard' //注销卡片

  static monetaryMobileCode = '/V3/UCenter/User/monetaryMobileCode' //发送金融卡短信
  static monetaryEmailCode = '/V3/UCenter/User/monetaryEmailCode' //发送金融卡邮件
  //管控资金
  static abnormalFreezeAsset = '/UserAml/FreezeAsset/getFreezeAssets' //管控资金管控金额
  static abnormalExchangeList = '/UserAml/FreezeAsset/getExchangList' //用户异常收款交易列表
  static abnormalAddressList = '/UserAml/FreezeAsset/getExchangAddressList' //用户异常地址列表
  static abnormalAddressDetail = '/UserAml/FreezeAsset/getAddressDetails' //地址收款详情列表
  static abnormalAssetDetail = '/UserAml/FreezeAsset/getUserAssetDetails' //管控资金处理详情
  static abnormalApplyAmlProve = '/UserAml/Prove/applyAmlProve' //提交申请证明材料
  static upload = '/UserAml/Prove/pushPhoto' //提交申请证明材料
  static uploadVideo = '/UserAml/Prove/pushVideo' //提交申请证明材料

  //金融衍生品
  static forexCoinMarket = '/api/derivs/pub/tick' //外汇行情
  static forexAccount = '/api/derivs/assets/account' //外汇账户信息,仓位信息
  static transferForexCoin = '/api/derivs/assets/transfer' //外汇划转接口
  static forexCreateOrder = '/api/derivs/trade/order'
  static forexOpenPositions = '/api/derivs/trace/openPositions' //外汇用户持仓列表
  static forexPlanOrders = '/api/derivs/trace/planOrders' //外汇当前委托单
  static forexHisPlanOrders = '/api/derivs/trace/hisPlanOrders' //历史委托单
  static forexCancelOrder = '/api/derivs/trade/cancel' //取消委托
  static forexStopOrder = '/api/derivs/trade/stopOrder' //设置止盈止损
  static forexClosePositions = '/api/derivs/trace/closePositions' //历史持仓
  static forexKline = '/api/derivs/pub/kline' //k线
  static forexInterestList = '/api/derivs/trace/interestList' //仓息记录
  static forexLeverList = '/api/derivs/leverage/list' //查询杠杆列表
  static forexUserLever = '/api/derivs/user/leverage' //查询用户symbol杠杆
  static forexUpdateLever = '/api/derivs/user/leverage/update' //编辑更新用户某symbol杠杆

  //zendesk chat
  static getJwt = '/Chat/Zendesk/getJwt' //第三方聊天

  //第三方支付
  static depositChannelAndCurrency = '/api/payment/channel/depositPaymentMethodConfigs' //获取入金渠道列表和可用法币币种
  static depositChannel = '/api/payment/deposit/pay' //mpay渠道入金
  static clientCancel = '/api/payment/deposit/clientCancel' //入金取消支付
  static depositOrderList = '/api/payment/deposit/orderList' //查询入金订单

  static getWithdrawPaymentChannelList = '/api/payment/channel/withdrawChannelAndCurrency' //出金渠道配置
  static withdrawChannel = '/api/payment/withdraw/pay' //渠道出金
  static withdrawOrderList = '/api/payment/withdraw/orderList' //查询出金订单
  static cancelWithdraw = '/api/payment/withdraw/cancelApply' //取消出金申请
  static getRateAll = '/api/payment/getRateAll' //查询全部汇率
}
